import React, {useState} from 'react';
import Select from 'react-select';
import axios from "axios";
import {config} from "../../config";
import {Modal, Button} from "react-bootstrap";
import {scrollToTop, toggleLoading, setActivePage} from "../../helper";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
const MerchantSignup = (props) => {
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [address, setAddress] = useState('');
    const [phone, setPhone] = useState('');
    const [business, setBusinessType] = useState(null);
    const [file, setFile] = useState('');
    const [fileErrorMessage, setFileErrorMessage] = useState('');
    const [validationErrorMessage, setValidationErrorMessage] = useState([]);
    const [show, setShow] = useState(false);
    const [otp, setOtp] = useState({
        first: '',
        second: '',
        third: '',
        fourth: '',
        fifth: '',
        sixth: ''
    });
    const [otpError, setOtpError] = useState('');
    const [otpSuccess, setOtpSuccess] = useState('');

    const handleClose = () => {
        setOtp({
            first: '',
            second: '',
            third: '',
            fourth: '',
            fifth: '',
            sixth: ''
        })
        setShow(false)
    };

    const checkLength = (e, key) => {
        e.preventDefault();
        if(e.target.value.length <= 1) {
            switch (key) {
                case 0:
                    console.log(e.target.value);
                    setOtp({...otp, first: e.target.value});
                    if(e.target.value) {
                        let newKey = key + 1;
                        let otpContainer = document.getElementById('otp-index-'+newKey);
                        otpContainer.focus();
                    }
                    break;
                case 1:
                    setOtp({...otp, second: e.target.value});
                    if(e.target.value) {
                        let newKey = key + 1;
                        let otpContainer = document.getElementById('otp-index-'+newKey);
                        otpContainer.focus();
                    }
                    break;
                case 2:
                    setOtp({...otp, third: e.target.value});
                    if(e.target.value) {
                        let newKey = key + 1;
                        let otpContainer = document.getElementById('otp-index-'+newKey);
                        otpContainer.focus();
                    }
                    break;
                case 3:
                    setOtp({...otp, fourth: e.target.value});
                    if(e.target.value) {
                        let newKey = key + 1;
                        let otpContainer = document.getElementById('otp-index-'+newKey);
                        otpContainer.focus();
                    }
                    break;
                case 4:
                    setOtp({...otp, fifth: e.target.value});
                    if(e.target.value) {
                        let newKey = key + 1;
                        let otpContainer = document.getElementById('otp-index-'+newKey);
                        otpContainer.focus();
                    }
                    break;
                case 5:
                    setOtp({...otp, sixth: e.target.value});
                    break;
                default:
                    break;
            }
        }
    }

    const verifyOtp = (e) => {
        toggleLoading(true);
        e.preventDefault();
        const code = otp.first+otp.second+otp.third+otp.fourth+otp.fifth+otp.sixth;
        axios.post(`${config.app.api}/auth/otp/verify`, {
            "code": code
        }, {
            headers: {
                'Authorization': 'Bearer '+localStorage.getItem('merchantToken'),
                'Content-Type': 'application/json'
            }
        }).then(response => {
            console.log(response);
            localStorage.setItem('otp', 'verified');
            setOtpSuccess('OTP Verified successfully.')
            setTimeout(() => {
                setOtpSuccess('');
                handleClose();
                props.setPage(2);
                toggleLoading(false);
            }, 3000);
        }).catch(error => {
            console.log(error);
            setOtpError('OTP wrong or not valid. Please recheck or request new OTP');
            setTimeout(() => {
                setOtpError('');
                handleClose()
            }, 5000);
            toggleLoading(false);
        })
    }

    const requestOtp = (e) => {
        e.preventDefault();
        toggleLoading(true);
        const code = otp.first+otp.second+otp.third+otp.fourth+otp.fifth+otp.sixth;
        console.log(otp.first+otp.second+otp.third+otp.fourth+otp.fifth+otp.sixth);
        axios.post(`${config.app.api}/auth/otp`, {},{
            headers: {
                'Authorization': 'Bearer '+localStorage.getItem('merchantToken'),
                'Content-Type': 'application/json'
            }
        }).then(response => {
            console.log(response);
            setOtpSuccess('OTP resent successfully.')
            setTimeout(() => {
                setOtpSuccess('')
            }, 5000);
            toggleLoading(false);

        }).catch(error => {
            console.log(error.response.data.message);
            setOtpError(error.response.data.message);
            setTimeout(() => {
                setOtpError('')
            }, 5000);
            toggleLoading(false);
        })
    }

    const checkOtpVerification = (e) => {
        e.preventDefault();
        if(localStorage.getItem('otp')) {
            props.setPage(2);
        } else {
            setShow(true);
        }
    }
    const handleChangeBusiness = business => {
        console.log(business);
        setBusinessType({business});
    }

    const changeFileUrl = async (event) => {
        toggleLoading(true);
        const fData = new FormData();
        fData.append('file', event.target.files[0])
        await axios.post(`${config.app.api}/upload/user/avatar`, fData)
        .then(response => {
            console.log(response.data.data.url)
            setFile(response.data.data.url)
            toggleLoading(false)
        }).catch(error => {
            toggleLoading(false)
            console.log(error.response.data.error.message)
            setFileErrorMessage(error.response.data.error.message);
        })
    }

    const handleUserSubmit = async (e) => {
        e.preventDefault();
        toggleLoading(true);
        await axios.post(`${config.app.api}/user/register`, {
            'firstName': firstName,
            'lastName': lastName,
            'email': email,
            'password': password,
            'address': address,
            'phone': '+'+phone,
            'role': 'vendor',
            'business': business ? JSON.stringify(business) : '',
            'path': file
        }, {
            headers: {
                "Content-Type": "application/json",
            }
        })
        .then(response => {
            console.log(response.data);
            localStorage.setItem('merchantToken', response.data.accessToken);
            localStorage.setItem('userId', response.data._id);
            localStorage.setItem('email', response.data.email);
            localStorage.setItem('businessType', response.data.businessType[0].slug);
            scrollToTop();
            toggleLoading(false);
            setShow(true);
            // handleShow();
        }).catch(error => {
            toggleLoading(false);
            scrollToTop();
            setValidationErrorMessage((prevState) => [...prevState, error.response.data.error.message])
            setTimeout(() => {
                setValidationErrorMessage([])
            }, 3000)
        });
    }


    return (
        <div className="col-md-6 signup-form-section partner" id={"partner-div"}
            style={localStorage.getItem('activePage') === '1' ? {display: 'block'}: {display: 'none'}}
        >
            <h5>Partner's Profile</h5>
            <ul className={validationErrorMessage.length === 0 ? 'd-none' : 'd-block'}>
                {validationErrorMessage.map((error,index) => {
                    return <li className='text-danger' key={index}>{error}</li>
                })}
            </ul>
            <Modal show={show} onHide={handleClose} >
                <Modal.Header closeButton >
                    <Modal.Title className={"text-center text-danger"} style={{width: '100%'}}>Phone Verification</Modal.Title>
                </Modal.Header>
                <form onSubmit={verifyOtp} >
                    <Modal.Body>
                        <p className={"text-center"}>Please provide your 6 digit verification code sent to your Mobile Number.</p><br/>
                        <div className="container otp-form">
                            <div className="row">
                                <div className="col-md-2">
                                    <input type="number" name="otp" className="form-control text-center"
                                           pattern="/^-?\d+\.?\d*$/"
                                           id={"otp-index-"+0}
                                           onChange={(e) => checkLength(e, 0)}
                                           required={true}
                                           value={otp.first}
                                    />
                                </div>
                                <div className="col-md-2">
                                    <input type="number" name="otp" className="form-control text-center"
                                           pattern="/^-?\d+\.?\d*$/"
                                           id={"otp-index-"+1}
                                           onChange={(e) => checkLength(e, 1)}
                                           required={true}
                                           value={otp.second}
                                    />
                                </div>
                                <div className="col-md-2">
                                    <input type="number" name="otp" className="form-control text-center"
                                           pattern="/^-?\d+\.?\d*$/"
                                           id={"otp-index-"+2}
                                           onChange={(e) => checkLength(e, 2)}
                                           required={true}
                                           value={otp.third}
                                    />
                                </div>
                                <div className="col-md-2">
                                    <input type="number" name="otp" className="form-control text-center"
                                           pattern="/^-?\d+\.?\d*$/"
                                           id={"otp-index-"+3}
                                           onChange={(e) => checkLength(e, 3)}
                                           required={true}
                                           value={otp.fourth}
                                    />
                                </div>
                                <div className="col-md-2">
                                    <input type="number" name="otp" className="form-control text-center"
                                           pattern="/^-?\d+\.?\d*$/"
                                           id={"otp-index-"+4}
                                           onChange={(e) => checkLength(e, 4)}
                                           required={true}
                                           value={otp.fifth}
                                    />
                                </div>
                                <div className="col-md-2">
                                    <input type="number" name="otp" className="form-control text-center"
                                           pattern="/^-?\d+\.?\d*$/"
                                           id={"otp-index-"+5}
                                           onChange={(e) => checkLength(e, 5)}
                                           required={true}
                                           value={otp.sixth}
                                    />
                                </div>
                            </div>
                        </div>
                        <br/>
                        <p className={"text-center"}>
                            Didn't get your verification code? &nbsp;
                            <button onClick={requestOtp} className="otp-request btn btn-outline-dark">Resend Now</button>
                        </p><br/>
                        <p className="text-center text-danger">
                            {otpError}
                        </p>
                        <p className="text-center text-success">
                            {otpSuccess}
                        </p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="warning" className={"text-white"} onClick={handleClose}>
                            Verify Later
                        </Button>
                        <Button variant="primary" type={"submit"}>
                            Verify Now
                        </Button>
                    </Modal.Footer>
                </form>
            </Modal>

            <form onSubmit={handleUserSubmit} method={"post"} className="signup-form" encType={"multipart/form-data"}>
                <div className="form-group row">
                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                        <label>First Name <span className="text-danger">*</span></label>
                        <input value={firstName} type="text" onChange={(e) => setFirstName(e.target.value)} name="firstName" className="form-control" required={true}/>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                        <label>Last Name <span className="text-danger">*</span></label>
                        <input value={lastName} type="text" onChange={(e) => setLastName(e.target.value)} name="lastName" className="form-control" required={true}/>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                        <label>Address <span className="text-danger">*</span></label>
                        <input value={address} type="text" onChange={(e) => setAddress(e.target.value)} name="address" className="form-control" required={true}/>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                        <label>Email <span className="text-danger">*</span></label>
                        <input value={email} type="email" onChange={(e) => setEmail(e.target.value)} name="email" className="form-control" required={true}/>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                        <label>Password <span className="text-danger">*</span></label>
                        <input type="password" onChange={(e) => setPassword(e.target.value)} name="password" className="form-control"
                               required={!localStorage.getItem('userId')}/>
                        <small className={localStorage.getItem('userId')? "text-danger" : "d-none" }>(Add password to change password)</small>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                        <label>Phone <span className="text-danger">*</span></label>
                        <PhoneInput
                            country={'hk'}
                            onlyCountries={['hk', 'np', 'in', 'vn', 'ph', 'sg', 'my']}
                            masks={{
                                hk: '.... ....',
                                np: '... ... ... .',
                                in: '.....-.....',
                                my: '..-....-....',
                                ph: '.... ......',
                                sg: '....-....',
                                vn: '... ... ... ... .'

                            }}
                            value={phone}
                            onChange={phone => setPhone(phone)}
                            countryCodeEditable={false}
                            enableSearch={true}
                            inputStyle={{width: '100%'}}
                        />
                    </div>
                </div>
                <div className="form-group row">
                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                        <label>Select Business Type <span className="text-danger">*</span></label>
                        <Select
                            value={business? business.label : business}
                            onChange={handleChangeBusiness}
                            options={props.businessType}
                            isMulti={false}
                            placeholder="Select Vendor Business Type"
                        />
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12 files">
                        <label>Upload Your Profile Picture <span className="text-danger">*(200x400)</span></label>
                        <input type="file" className="form-control" accept="image/png, image/jpeg, image/jpg, image/svg, image/gif" onChange={changeFileUrl}
                               required={!localStorage.getItem('userId')}/>
                        <span className={file === '' ? 'd-block text-danger' : 'd-none'}>{fileErrorMessage ? fileErrorMessage : "You must attach file"}</span>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12 text-center"></div>
                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12 text-center">
                        <img src={config.app.api+'/'+file} alt={file} style={{maxWidth: '25%', marginTop: '20px'}} />
                    </div>
                </div>
                <div className="form-group submit-btn text-center" style={{position: 'absolute', bottom: '1rem'}}>
                    <button className={localStorage.getItem('userId') ? "d-none" : "btn btn-success text-white d-inline-block"}
                        type={"submit"}
                    >{localStorage.getItem('userId') ? 'Update' : 'Next' }</button>
                </div>
                <div style={{position: 'absolute', bottom: '1rem'}} className="form-group submit-btn text-center">
                    <button onClick={checkOtpVerification} className={localStorage.getItem('userId') ? 'btn btn-success text-white' : 'd-none'}>Next</button>
                </div>
            </form>
        </div>
    )
}

export default MerchantSignup;