import React from "react";

const CategoryTitle = (props) => {
    return (
        <div className="home-category-title-section">
            <h1>{props.title}
                <span className={props.link ? "view-all-btn" : "d-none"}><button className={"text-white"}>View All</button></span>
            </h1>
        </div>
    )
}
export default CategoryTitle;