import React from "react";
import {Col, Row} from "react-bootstrap";
import CategoryTitle from "../include/CategoryTitle";

const BestDeals = () => {
    return (
        <Row className={'home-best-deals'}>
            <CategoryTitle title={"best deals"} />
            <Row>
                <Col xl={4} lg={4} md={4} sm={6} xs={12}>
                    <a href="#">
                        <img src="/deals/1.png" className={"img-fluid"} alt="deal"/>
                    </a>
                </Col>
                <Col xl={4} lg={4} md={4} sm={6} xs={12}>
                    <a href="#">
                        <img src="/deals/2.png" className={"img-fluid"} alt="deal"/>
                    </a>
                </Col>
                <Col xl={4} lg={4} md={4} sm={6} xs={12}>
                    <a href="#">
                        <img src="/deals/3.png" className={"img-fluid"} alt="deal"/>
                    </a>
                </Col>
            </Row>
        </Row>
    )
}
export default BestDeals;