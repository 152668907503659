import React from "react";
const RestaurantCard = (props) => {
    console.log(props.image)
    return (
        <div className="card restaurant-card">
            <div className="card-img-top" style={{backgroundImage: `url(${props.image})`}}>
                {/*<img src={props.image} alt="name" className={"img-fluid"}/>*/}
                <span className={props.status === 'open' ? "restaurant-status text-success" : "restaurant-status text-danger"}>
                    {props.status}
                </span>
                <span className="restaurant-rating">
                    <img src="/restaurant/rating.png" alt="rating"/>&nbsp;
                    {props.rating}
                </span>
            </div>
            <div className="card-body">
                <h5 className="card-title">{props.title}</h5>
                <p className="card-text">
                    {props.address}
                </p>
            </div>
        </div>
    )
}

export default RestaurantCard;