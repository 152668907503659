// MyGoogleMaps.js
import React, { useState, useCallback, useRef } from 'react';
import {
  GoogleMap, useLoadScript, Marker
} from "@react-google-maps/api";
import {config} from "../config";
import usePlacesAutocomplete, {getGeocode, getLatLng} from "use-places-autocomplete";
import Geocode from "react-geocode";
import {
  Combobox,
  ComboboxInput,
  ComboboxPopover,
  ComboboxList,
  ComboboxOption
} from "@reach/combobox";
import "@reach/combobox/styles.css";

const libraries = ["places"];
const mapContainerStyle= {
  width: '80vw',
  height: '60vh'
}
const center = {
  lat: 27.6981,
  lng: 85.3592
}
// const addressBoxStyle = {
//   maxWidth: '100%',
//   height: 'auto',
//   padding: '0.5rem',
//   borderRadius: '5px',
//   border: '1px solid #ccc'
// }

const MyGoogleMap = ({handleChangeAddress}) => {
  const {isLoaded, loadError} = useLoadScript({
    googleMapsApiKey: config.app.googleApi,
    libraries
  });
  const [marker, setMarker] = useState({
    lat: '',
    lng: ''
  })

  const clickOnMap = useCallback(async (e) => {
    console.log(e)
    setMarker({
      lat: e.latLng.lat(),
      lng: e.latLng.lng()
    })
    pantTo({lat:e.latLng.lat(), lng:e.latLng.lng()})
    getAddress({lat: e.latLng.lat(), lng: e.latLng.lng()})
  }, []);

  const mapRef = useRef();
  const onMapLoad = useCallback((map) => {
    mapRef.current = map;
  }, []);

  const pantTo = useCallback(({lat, lng}) => {
    mapRef.current.panTo({lat, lng});
    mapRef.current.setZoom(14);
    setMarker({
      lat: lat,
      lng: lng
    });
    getAddress({lat: lat, lng: lng})
  }, []);

  const getAddress = async ({lat, lng}) => {
    await Geocode.setApiKey(config.app.googleApi)
    await Geocode.fromLatLng(lat, lng).then(
      response => {
        const address = response.results;
        console.log(address);
        handleChangeAddress(response.results[1].formatted_address, lat, lng)
      },
      error => {
        console.error(error);
      }
    );
  }
  if(loadError) return (<div className={"row"}>Error</div>);
  if(!isLoaded) return (<div className={"row"}>Map</div>);
  return (
    <>
      <div className="row google-map">
        <Search panTo={pantTo}/>
        <Locate panTo={pantTo}/>
        <GoogleMap
          mapContainerStyle={mapContainerStyle}
          zoom={9}
          center={center}
          onClick={clickOnMap}
          onLoad={onMapLoad}
        >
          {<Marker position={marker} />}
        </GoogleMap>
      </div>
    </>
  );
}
const Locate = ({panTo}) => {
  return (
    <a className={"locate"}
       onClick={() => {
         navigator.geolocation.getCurrentPosition(
           (position) => {
             panTo({
               lat: position.coords.latitude,
               lng: position.coords.longitude
             });
           },
           ()=> null,)
       }}
    >
      <img src="/compass.png" alt="compass-locate me"/>
    </a>
  );
}
const Search = ({panTo}) => {
  const {
    ready,
    value,
    suggestions: {status, data},
    setValue,
    clearSuggestions
  } = usePlacesAutocomplete({
    requestOptions: {
      location: {lat: () => center.lat, lng: () => center.lng},
      radius: 200 * 1000
    }
  })

  return (
    <div className={"search-box"}>
      <Combobox onSelect={async (address) => {
        setValue(address, false);
        clearSuggestions();
        try {
          const results = await getGeocode({address});
          const {lat, lng} = await getLatLng(results[0]);
          panTo({lat, lng});
        } catch (error) {
          console.log(error);
        }
      }}>
        <ComboboxInput className={"form-control"} value={value} onChange={(e) => setValue(e.target.value)}
                       disabled={!ready} placeholder="Search an address" autocomplete={false} />
        <ComboboxPopover>
          <ComboboxList>
            {status === "OK" && data.map(({description, id}) => (
              <ComboboxOption key={id} value={description} />
            ))}
          </ComboboxList>
        </ComboboxPopover>
      </Combobox>
    </div>
  )
}
export default MyGoogleMap;

