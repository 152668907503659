import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import {changeHeaderFooter} from "../helper";

const Welcome = () => {
    const [height, setHeight] = useState(0);
    const [width, setWidth] = useState(0);
    const getHeight = () => {
        setWidth(window.innerWidth);
        setHeight(window.innerHeight);
    };
    useEffect(() => {
        getHeight();
        if (localStorage.getItem('email')) {
            localStorage.removeItem('email');
        }
        changeHeaderFooter('welcome');
    }, [height, width]);
    return (
        <div className="row no-gutters welcome-page">
            <div className="col-md-12 col-lg-3 col-md-6 col-sm-6 col-xs-12 welcome-col" style={(width<992 && width>767) ? {height: height/2, maxWidth: width/2} : {height: height}}>
                <div className="background bg_theApp1" style={(width<992 && width>767) ? {height: height/2, maxWidth: width/2} : {height: height}}>
                    <h1 className="app-name">Food Delivery</h1>
                    <p className="app-name">Save a fortune with ultimate lifestyle</p>
                    <div className="link-btn">
                        <Link to="/restaurant" className="food-btn">Start Exploring</Link>
                    </div>
                    <img src={'/welcome/foreground/food.png'} className="img-fluid" alt="foreground" />
                </div>
            </div>
            <div className="col-md-12 col-lg-3 col-md-6 col-sm-6 col-xs-12 welcome-col" style={(width<992 && width>767) ? {height: height/2, maxWidth: width/2} : {height: height}}>
                <div className="background bg_theApp2" style={(width<992 && width>767) ? {height: height/2, maxWidth: width/2} : {height: height}}>
                    <h1>Grocery Delivery</h1>
                    <p>Loyality and rewards solutions for business around the globe</p>
                    <div className="link-btn">
                        <Link to="/restaurant" className="business-btn">Find Out MOre</Link>
                    </div>
                    <img src={'/welcome/foreground/grocery.png'} className="img-fluid" alt="foreground" />
                </div>
            </div>
            <div className="col-md-12 col-lg-3 col-md-6 col-sm-6 col-xs-12 welcome-col" style={(width<992 && width>767) ? {height: height/2, maxWidth: width/2} : {height: height}}>
                <div className="background bg_theApp3" style={(width<992 && width>767) ? {height: height/2, maxWidth: width/2} : {height: height}}>
                    <h1>Laundry Service</h1>
                    <p>Discover great deals at exciting destinations all over the world</p>
                    <div className="link-btn">
                        <a href="/restaurant" className="travel-btn">take me there</a>
                    </div>
                    <img src={'/welcome/foreground/laundry.png'} className="img-fluid" alt="foreground" />
                </div>
            </div>
            <div className="col-md-12 col-lg-3 col-md-6 col-sm-6 col-xs-12 welcome-col" style={(width<992 && width>767) ? {height: height/2, maxWidth: width/2} : {height: height}}>
                <div className="background bg_theApp4" style={(width<992 && width>767) ? {height: height/2, maxWidth: width/2} : {height: height}}>
                    <h1>Our Partners</h1>
                    <p>Join our family, take your business to the next level.</p>
                    <div className="signin-div">
                        <h6>Already a Partner ?</h6>
                        <a href="/">Sign In</a>
                    </div>
                    <div className="link-btn">
                        <h6>Intrerested?</h6>
                        <Link to="/business/signup" className="partner-btn">get in touch</Link>
                    </div>
                    <img src={'/welcome/foreground/partners.png'} className="img-fluid partner-image" alt="foreground" />
                </div>
            </div>

        </div>
    )
}

export default Welcome;