import React from "react";
import {
    Navbar, 
    Nav
} from 'react-bootstrap';
import {Switch, Route, Link, Redirect, useLocation} from 'react-router-dom';
import Restaurant from '../pages/Restaurant';
import Logo from "./../logo.png";
import {config} from "../config";
import Welcome from "../pages/Welcome";
import Merchant from "../pages/Merchant";
import CongratulationPage from "../components/merchant/CongratulationPage";
const Header = () => {
    const { pathname } = useLocation();
    return (
        <>
            <div className="container-fluid welcome-nav">
                <div className="row">
                    <div className="col-md-6 col-sm-8 col-xs-8">
                        <Link to="/"><img src={Logo} alt="logo"/></Link>
                    </div>
                    <div className="col-md-6 col-sm-4 col-xs-4">
                        <ul className="ml-auto">
                            <li className="nav-item language-selector-nav">
                                {/*<span className="globe"><i className="fas fa-globe"></i></span><LanguageSelector />*/}
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <Navbar expand="lg" className={"restaurant-nav"}>
                <Link to={"/restaurant"} className="navbar-brand">
                    <img src={Logo} alt="logo"/>
                </Link>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="ml-auto">
                        <Link to={"/restaurant"} className="nav-link">Home</Link>
                        <Nav.Link href="#link">Restaurants</Nav.Link>
                        <Nav.Link href="#link">Cart</Nav.Link>
                        <Nav.Link className="login-btn" href="#link">Log In</Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
            <Switch>
                <Redirect from="/:url*(/+)" to={pathname.slice(0, -1)} />
                <Route path='/restaurant' component={Restaurant} />
                <Route path='/business/signup' component={Merchant} />
                <Route path={'/business/congratulations'} component={CongratulationPage} />
                <Route exact path='/' component={Welcome} />
            </Switch>
        </>
    )
}

export default Header;