import React from "react";
import {Col, Container, Row} from "react-bootstrap";
import Logo from "./../logo.png";

const Footer = () => {
    return (
        <footer className={"footer-section restaurant-footer row"}>
            <Col xl={3} lg={4} md={4} sm={4} xs={12}>
                <div className={"logo"}>
                    <a><img src={Logo} alt="Logo"/></a>
                    <p>or lipsum as it is sometimes known, is dummy text used in laying out print, graphic or web designs.</p>
                </div>
                <div className="social-link">
                    <h5>Socials</h5>
                    <ul>
                        <li>
                            <a href="https://www.facebook.com/faaizihk" target={"_blank"}>
                                <img src="/fb.png" alt="facebook" className={"img-fluid"}/>
                            </a>
                        </li>
                        <li>
                            <a href="https://www.linkedin.com/company/fai-zi/" target={"_blank"}>
                                <img src="/ln.png" alt="Linkedin" className={"img-fluid"}/>
                            </a>
                        </li>
                        <li>
                            <a href="#" target={"_blank"}>
                                <img src="/insta.png" alt="instagram" className={"img-fluid"}/>
                            </a>
                        </li>
                    </ul>
                </div>
            </Col>
            <Col xl={9} lg={8} md={8} sm={8} xs={12} style={{padding: '0'}}>
                <Row className={"footer-links"}>
                    <Col xl={4} lg={4} sm={5} xs={12}>
                        <ul>
                            <h5>Quick Links</h5>
                            <li>
                                <a href="#">About</a>
                            </li>
                            <li>
                                <a href="#">Blog</a>
                            </li>
                            <li>
                                <a href="#">FAQ</a>
                            </li>
                            <li>
                                <a href="#">Contact</a>
                            </li>
                        </ul>
                    </Col>
                    <Col xl={4} lg={4} sm={4} xs={12}>
                        <ul>
                            <h5>Categories</h5>
                            <li>
                                <a href="#">Featured Restaurants</a>
                            </li>
                            <li>
                                <a href="#">Special Food</a>
                            </li>
                            <li>
                                <a href="#">Best Picks</a>
                            </li>
                        </ul>
                    </Col>
                    <Col xl={4} lg={4} sm={3} xs={12}>
                        <ul>
                            <h5>Contact</h5>
                            <li>
                                <a href="#">+852 XXXX-XXXX</a>
                            </li>
                            <li>
                                <a href="#">Lantau Island</a>
                            </li>
                            <li>
                                <a href="#">Hong Kong</a>
                            </li>
                        </ul>
                    </Col>
                </Row>
            </Col>
            <Container className={"footer-text"}>
                @2021 Fai Zi All Rights Reserved | Designed By: <a href="https://www.goswivt.com" target={"_blank"}>Swivt Technologies</a>
            </Container>
        </footer>
    )
}

export default Footer;