import React, {useState, useEffect} from "react";
import axios from "axios";
import MyGoogleMap from "../../include/Map";
import {scrollToTop, toggleLoading} from "../../helper";
import PhoneInput from 'react-phone-input-2';
import {config} from "../../config";
import 'react-phone-input-2/lib/style.css';

const BusinessProfile = (props) => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [address, setAddress] = useState('');
    const [bannerImage] = useState('');
    const [logoImage] = useState('');
    const [desc, setDesc] = useState('');
    const [fileLogo, setFileLogo] = useState(null)
    const [fileBanner, setFileBanner] = useState(null)
    const [logoUrl, setLogoUrl] = useState('');
    const [bannerUrl, setBannerUrl] = useState('');
    const [error, setErrorMessage] = useState('');
    const [success, setSuccessMessage] = useState('');
    const [validationErrorMessage, setValidationErrorMessage] = useState([])
    const [lat, setLat] = useState('');
    const [lng, setLng] = useState('');

    const handleChangeAddress = (address, lat, lng) => {
        setAddress(address)
        setLat(lat)
        setLng(lng)
    }

    const onLogoChange = event => {
        setFileLogo(event.target.files[0])
        setLogoUrl(URL.createObjectURL(event.target.files[0]))
    }

    const onBannerChange = e => {
        setFileBanner(e.target.files[0])
        setBannerUrl(URL.createObjectURL(e.target.files[0]))
    }

    const storeBusinessDetail = async (e) => {
        toggleLoading(true);
        e.preventDefault()
        const formData = new FormData();
        formData.append('userId', localStorage.getItem('userId'));
        formData.append('businessType', localStorage.getItem('businessType'));
        formData.append('name', name);
        formData.append('email', email);
        formData.append('address', address);
        formData.append('displayAddress', address);
        formData.append('phone', phone);
        formData.append('desc', desc);
        formData.append('lat', lat);
        formData.append('lng', lng);
        if(fileLogo !== null) {
            formData.append('file', fileLogo)
        }
        if(fileBanner !== null) {
            formData.append('file', fileBanner)
        }
        await axios.post(`${config.app.api}/business/store`, formData,
            {
                headers: {
                    'Authorization': 'Bearer '+localStorage.getItem('merchantToken'),
                    'Content-Type': 'application/json'
                }
            })
            .then(response => {
                localStorage.setItem('businessId', response.data.business._id);
                props.setPage(3);
                scrollToTop();
                toggleLoading(false);
            }).catch(error => {
                toggleLoading(false)
                scrollToTop()
                console.log(error.response);
                setValidationErrorMessage((prevState) => [...prevState, error.response.data.message])
                // setErrorMessage(error.response.data.message)
                setTimeout(() => {
                    setValidationErrorMessage([])
                    setErrorMessage('');
                }, 3000)
            })
    }

    return (
        <div className={"signup-form-section business col-md-6"} id={"business-div"}>
            <h5>Business Profile</h5>
            <h6 className={error ? "info-message text-danger": "info-message text-success"}>
                {error}
            </h6>
            <ul className={validationErrorMessage.length === 0 ? 'd-none' : 'd-block'}>
                {validationErrorMessage.map((error,index) => {
                    return <li className='text-danger' key={index}>{error}</li>
                })}
            </ul>
            <form onSubmit={storeBusinessDetail} className={"signup-form"} encType={"multipart/form-data"}>
                <div className="form-group row">
                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                        <label>Business Name <span className="text-danger">*</span></label>
                        <input className="form-control" type="text" name="name" value={name}
                               onChange={(e) => setName(e.target.value)} required/>
                        <span className={name === '' ? 'd-block text-danger' : 'd-none'}>Business Name cannot be empty.</span>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                        <label>Business Email <span className="text-danger">*</span></label>
                        <input className="form-control" type="email" name="email" value={email}
                               onChange={(e) => setEmail(e.target.value)} required/>
                        <span className={email === '' ? 'd-block text-danger' : 'd-none'}>Business Email cannot be empty.</span>
                    </div>
                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <label>Phone <span className="text-danger">*</span></label>
                        <PhoneInput
                            country={'hk'}
                            onlyCountries={['hk', 'np', 'in', 'vn', 'ph', 'sg', 'my']}
                            masks={{
                                hk: '.... ....',
                                np: '... ... ... .',
                                in: '.....-.....',
                                my: '..-....-....',
                                ph: '.... ......',
                                sg: '....-....',
                                vn: '... ... ... ... .'

                            }}
                            value={phone}
                            onChange={phone => setPhone(phone)}
                            countryCodeEditable={false}
                            enableSearch={true}
                            inputStyle={{width: '100%'}}
                        />
                        {/*<input className="form-control" type="tel" min={8} max={8} name="phone" value={phone}*/}
                        {/*       onChange={(e) => setPhone(e.target.value)} required/>*/}
                        <span className={phone === '' ? 'd-block text-danger' : 'd-none'}>Business phone cannot be empty.</span>
                    </div>
                </div>
                <div className="form-group row">
                    <div className="col-12">
                        <label htmlFor="desc">Description <span className="text-danger">*</span></label>
                        <textarea
                            name="desc"
                            className={"form-control"}
                            rows={5}
                            value={desc}
                            required
                            onChange={(e) => setDesc(e.target.value)}
                        />
                    </div>
                </div>
                <br/>
                <div className="form-group row">
                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                        <label>Business Logo <span className="text-danger">*(size: 200x400)</span></label><br/>
                        <input type="file" onChange={onLogoChange} accept="image/png, image/jpeg, image/jpg" required={true}/><br/><br/>
                        <img style={{maxWidth: '50%', height: 'auto'}} src={logoUrl? logoUrl : `${config.app.api}/`+logoImage} alt="logo"/>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                        <label>Business Banner Image <span className="text-danger">*(size: 400x800)</span></label><br/>
                        <input type="file" onChange={onBannerChange} accept="image/png, image/jpeg, image/jpg" required={true}/><br/><br/>
                        <img style={{maxWidth: '50%', height: 'auto'}} src={bannerUrl? bannerUrl : `${config.app.api}/`+bannerImage} alt="Banner"/>
                    </div>
                </div>
                <br/>
                <div className="form-group row">
                    <div className="col-md-6 col-sm-6 col-xs-12">
                        <label>Business Address <span className="text-danger">*</span></label>
                        {/*<input className="form-control" type="text" name="address" value={address} disabled={true}/>*/}
                    </div>
                    <div className="col-md-6 col-sm-6 col-xs-12 display-address">
                        <label>Business Display Address <span className="text-danger">*</span></label>
                        <input className="form-control" type="text" name="displayAddress" disabled={true} value={address}/>
                    </div>
                </div>
                <br/>
                <div className="form-group">
                    <MyGoogleMap handleChangeAddress={handleChangeAddress}/>
                </div>
                <div className="form-group row submit-btn">
                    <div className={"col-md-6"}>
                        <button onClick={()=>props.setPage(1)} style={{float: 'right', marginTop: '20px', background: "#ccc"}} className="btn text-white" >Back</button>
                    </div>
                    <div className={localStorage.getItem('businessId') ? "col-md-6 d-none" : "col-md-6 d-inline-block"}>
                        <button style={{float: 'left', marginTop: '20px'}} className="btn btn-success text-white" type="submit">
                            {localStorage.getItem('businessId') ? "Update" : "Save & Continue"}
                        </button>
                    </div>
                    <div className={localStorage.getItem('businessId') ? "col-md-6 d-block" : "d-none"}>
                        <button onClick={()=>props.setPage(3)} style={{float: 'left', marginTop: '20px'}}
                            className="btn btn-success text-white"
                        >Next</button>
                    </div>
                </div>
            </form>
        </div>
    )
}

export default BusinessProfile;