import React from "react";
import {Row, Col} from "react-bootstrap";
import CategoryTitle from "../include/CategoryTitle";
import ProductCard from "../include/ProductCard";
import RestaurantCard from "../include/RestaurantCard";

const BestSelectedFood = () => {
    return (
        <Row className={'home-best-selected-food'}>
            <div className="best-selected-food">
                <CategoryTitle title={"best selected food"} link={"/"}/>
                <Row className="product-list">
                    <Col xl={3} lg={3} md={4} sm={6} xs={12}>
                        <ProductCard
                            title={"food name"}
                            restaurantName={"restaurant name"}
                            image={"/popular/PF1.png"}
                            rating={'4.5'}
                        />
                    </Col>
                    <Col xl={3} lg={3} md={4} sm={6} xs={12}>
                        <ProductCard
                            title={"food name"}
                            restaurantName={"restaurant name"}
                            image={"/popular/PF2.png"}
                            rating={'3.2'}
                        />
                    </Col>
                    <Col xl={3} lg={3} md={4} sm={6} xs={12}>
                        <ProductCard
                            title={"food name"}
                            restaurantName={"restaurant name"}
                            image={"/popular/PF1.png"}
                            rating={'4.5'}
                        />
                    </Col>
                    <Col xl={3} lg={3} md={4} sm={6} xs={12}>
                        <ProductCard
                            title={"food name"}
                            restaurantName={"restaurant name"}
                            image={"/popular/PF2.png"}
                            rating={'3.2'}
                        />
                    </Col>
                    <Col xl={3} lg={3} md={4} sm={6} xs={12}>
                        <ProductCard
                            title={"food name"}
                            restaurantName={"restaurant name"}
                            image={"/popular/PF1.png"}
                            rating={'4.5'}
                        />
                    </Col>
                    <Col xl={3} lg={3} md={4} sm={6} xs={12}>
                        <ProductCard
                            title={"food name"}
                            restaurantName={"restaurant name"}
                            image={"/popular/PF2.png"}
                            rating={'3.2'}
                        />
                    </Col>
                    <Col xl={3} lg={3} md={4} sm={6} xs={12}>
                        <ProductCard
                            title={"food name"}
                            restaurantName={"restaurant name"}
                            image={"/popular/PF1.png"}
                            rating={'4.5'}
                        />
                    </Col>
                    <Col xl={3} lg={3} md={4} sm={6} xs={12}>
                        <ProductCard
                            title={"food name"}
                            restaurantName={"restaurant name"}
                            image={"/popular/PF2.png"}
                            rating={'3.2'}
                        />
                    </Col>
                </Row>
            </div>
        </Row>
    )
}
export default BestSelectedFood;