module.exports = {
    changeHeaderFooter: (slug) => {
        let welcomeNav = document.getElementsByClassName('welcome-nav');
        let restaurantHeader = document.getElementsByClassName('restaurant-nav');
        let restaurantFooter = document.getElementsByClassName('restaurant-footer');
        if(slug === 'welcome') {
            welcomeNav[0].style.display = 'flex';
            restaurantHeader[0].style.display = 'none';
            restaurantFooter[0].style.display = 'none';
        } else if(slug === 'restaurant') {
            welcomeNav[0].style.display = 'none';
            restaurantHeader[0].style.display = 'flex';
            restaurantFooter[0].style.display = 'flex';
        } else {
            welcomeNav[0].style.display = 'none';
            restaurantHeader[0].style.display = 'none';
            restaurantFooter[0].style.display = 'none';
        }
    },
    scrollToTop: () => {
        window.scrollTo(0, 0)
    },
    toggleLoading: (loading) => {
        const backgroundOverlay = document.getElementById('background-overlay');
        if(loading === true) {
            backgroundOverlay.style.display = 'block';
        } else {
            backgroundOverlay.style.display = 'none';
        }
    },
    // limit 6 digit number only
    limit: (element, max_chars) => {
        if(element.value.length > max_chars) {
            element.value = element.value.substr(0, max_chars);
        }
    },
    minmax: (value, min, max) => {
        if(parseInt(value) < min || isNaN(parseInt(value)))
            return 0;
        else if(parseInt(value) > max)
            return 100;
        else return value;
    },
    //set active merchant login page
    setActivePage: (page) => {
        localStorage.setItem('activePage', page);
        const partner = document.getElementById('partner-div');
        const business = document.getElementById('business-div');
        const doc = document.getElementById('doc-div');
        const term = document.getElementById('term-div');
        if (partner && business && doc && term) {
            switch (page) {
                case 1:
                    partner.style.display = 'block';
                    business.style.display = 'none';
                    doc.style.display = 'none';
                    term.style.display = 'none';
                    break;
                case 2:
                    business.style.display = 'block';
                    partner.style.display = 'none';
                    doc.style.display = 'none';
                    term.style.display = 'none';
                    break;
                case 3:
                    doc.style.display = 'block';
                    business.style.display = 'none';
                    partner.style.display = 'none';
                    term.style.display = 'none';
                    break;
                case 4:
                    term.style.display = 'block';
                    partner.style.display = 'none';
                    business.style.display = 'none';
                    doc.style.display = 'none';
                    break;
                default:
                    partner.style.display = 'block';
                    business.style.display = 'none';
                    doc.style.display = 'none';
                    term.style.display = 'none';
                    break;
            }
        }
    },
}