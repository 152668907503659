import React, {useEffect, useState} from "react";
import axios from "axios";
import {config} from "../../config";
import {setActivePage, toggleLoading} from "../../helper";
import BannerDummy from '../../images/thumbdummy.png';

const DocumentUpload = (props) => {
    const [nationalId, setNationalId] = useState(null);
    const [businessRegistration, setBR] = useState(null);
    const [addressProof, setAddressProof] = useState(null);
    const [fileErrorMessage, setFileErrorMessage] = useState({
        id: '',
        br: '',
        ap: ''
    });
    const [success, setSuccess] = useState('');

    const onDocumentUpload = async (event, docName) => {
        toggleLoading(true);
        const fData = new FormData();
        fData.append('file', event.target.files[0])
        await axios.post(`${config.app.api}/upload/user/documents`, fData)
            .then(response => {
                console.log(response.data.data.url);
                toggleLoading(false);
                switch (docName) {
                    case 'nationalId':
                        setNationalId(response.data.data.url);
                        break;
                    case 'businessRegistration':
                        setBR(response.data.data.url);
                        break;
                    default:
                        setAddressProof(response.data.data.url);
                        break;
                }
            }).catch(error => {
                console.log(error.response.data.error.message);
                toggleLoading(false);
                switch (docName) {
                    case 'nationalId':
                        setFileErrorMessage({id: error.response.data.error.message, br: '', ap: ''});
                        document.getElementById('ni').value = null;
                        break;
                    case 'businessRegistration':
                        setFileErrorMessage({id: '', br: error.response.data.error.message, ap: ''});
                        document.getElementById('br').value = null;
                        break;
                    default:
                        setFileErrorMessage({id: '', br: '', ap: error.response.data.error.message});
                        document.getElementById('ap').value = null;
                        break;
                }
            })
    }

    const documentSubmit = async(e) => {
        toggleLoading(true);
        e.preventDefault();
        if(localStorage.getItem('documentId')) {
            await axios.patch(`${config.app.api}/documents/update/${localStorage.getItem('documentId')}`, {
                'userId': localStorage.getItem('userId'),
                'nationalId': nationalId,
                'businessRegistration': businessRegistration,
                'addressProof': addressProof
            }, {
                headers: {
                    'Authorization': 'Bearer '+localStorage.getItem('merchantToken'),
                    'Content-Type': 'application/json'
                }
            }).then(response => {
                console.log(response.data);
                localStorage.setItem('documentId', response.data.data._id);
                props.setPage(4);
                toggleLoading(false);
            }).catch(e => {
                console.log(e);
                toggleLoading(false);
            })
        } else {
            await axios.post(`${config.app.api}/documents/store`, {
                'userId': localStorage.getItem('userId'),
                'nationalId': nationalId,
                'businessRegistration': businessRegistration,
                'addressProof': addressProof
            }, {
                headers: {
                    'Authorization': 'Bearer '+localStorage.getItem('merchantToken'),
                    'Content-Type': 'application/json'
                }
            }).then(response => {
                console.log(response.data);
                localStorage.setItem('documentId', response.data.data._id);
                props.setPage(4);
                toggleLoading(false);
            }).catch(e => {
                console.log(e);
                toggleLoading(false);
            })
        }
    }
    // useEffect(() => {
    //     if(parseInt(localStorage.getItem('activePage')) === 2) {
    //         setSuccess('Congratulations !!! you have successfully registered your business. Please provide your Identity Here.');
    //     }
    //     setTimeout(() => {
    //         setSuccess('');
    //     }, 3000);
    // }, [parseInt(localStorage.getItem('activePage'))])
    return(
        <div className={"col-md-6 signup-form-section document"} id={"doc-div"}>
            <h5>Documents Upload</h5>
            <h6 className={"info-message text-success"}>
                {success}
            </h6>
            <form className={"signup-form row"}>
                <div className="form-group row">
                    <div className="col-lg-8 col-md-6 col-sm-12">
                        <label>Upload Your National Id or Passport Image <span className="text-danger">*</span></label><br/>
                        <input type="file" className="form-control"
                           accept="image/png, image/jpeg, image/jpg"
                           id={"ni"}
                           onChange={(e) => onDocumentUpload(e, 'nationalId')} required={true}/><br/>
                       <span className={fileErrorMessage.id ? 'd-block text-danger' : 'd-none'}>{fileErrorMessage.id ? fileErrorMessage.id : "You must attach your National Id or Passport Image"}</span>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-12" style={{textAlign: '-webkit-right'}}>
                        <img src={nationalId ? config.app.api+'/'+nationalId : BannerDummy}
                             alt={nationalId ? nationalId : 'dummy'} style={{maxWidth: '200px', height: '100px', marginTop: '20px'}} />
                    </div>
                </div>
                <br/>
                <div className="form-group row">
                    <div className="col-lg-8 col-md-6 col-sm-12">
                        <label>Upload Your Business Registration Image <span className="text-danger">*</span></label><br/>
                        <input type="file" className="form-control"
                            accept="image/png, image/jpeg, image/jpg"
                            id={"br"}
                            onChange={(e) => onDocumentUpload(e, 'businessRegistration')} required={true}/><br/>
                        <span className={fileErrorMessage.br ? 'd-block text-danger' : 'd-none'}>{fileErrorMessage.br ? fileErrorMessage.br : "You must attach your Business Registration Image"}</span>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-12" style={{textAlign: '-webkit-right'}}>
                        <img src={businessRegistration? config.app.api+'/'+businessRegistration : BannerDummy}
                             alt={businessRegistration ? businessRegistration : 'dummy'} style={{maxWidth: '200px', height: '100px', marginTop: '20px'}} />
                    </div>
                </div>
                <br/>
                <div className="form-group row">
                    <div className="col-lg-8 col-md-6 col-sm-12">
                        <label>Upload Your Address Proof Image <span className="text-danger">*</span></label><br/>
                        <input type="file" className="form-control"
                            accept="image/png, image/jpeg, image/jpg"
                            id={"ap"}
                            onChange={(e) => onDocumentUpload(e, 'addressProof')} required={true}/><br/>
                        <span className={fileErrorMessage.ap ? 'd-block text-danger' : 'd-none'}>{fileErrorMessage.ap ? fileErrorMessage.ap : "You must attach your Address Proof Image"}</span>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-12" style={{textAlign: '-webkit-right'}}>
                        <img src={addressProof? config.app.api+'/'+addressProof : BannerDummy} alt={addressProof ? addressProof : 'dummy'} style={{maxWidth: '200px', height: '100px', marginTop: '20px'}} />
                    </div>
                </div>
                <div className="form-group row submit-btn">
                    <div className={"col-md-6"}>
                        <button onClick={()=>props.setPage(2)} style={{float: 'right', marginTop: '20px', background: "#ccc"}} className="btn text-white" >Back</button>
                    </div>
                    <div className={localStorage.getItem('documentId') ? "col-md-4 d-none" : "col-md-6 d-inline-block"}>
                        <button style={{float: 'left', marginTop: '20px'}} className="btn btn-success text-white" type="submit"
                            onClick={(e) => documentSubmit(e)}
                        >
                            {localStorage.getItem('documentId') ? "Update" : "Save & Continue"}
                        </button>
                    </div>
                    <div className={localStorage.getItem('documentId') ? "col-md-6 d-inline-block" : "d-none"}>
                        <button onClick={()=>props.setPage(4)} style={{float: 'left', marginTop: '20px'}}
                                className="btn btn-success text-white"
                        >Next</button>
                    </div>
                </div>
            </form>
        </div>
    )
}

export default DocumentUpload;