// MyLocations.js
import React, { useState, useCallback, useRef } from 'react';
import {Row} from 'react-bootstrap';
import {
    GoogleMap, useLoadScript, Marker
} from "@react-google-maps/api";
import {config} from "../config";
import usePlacesAutocomplete, {getGeocode, getLatLng} from "use-places-autocomplete";
import Geocode from "react-geocode";
import {
    Combobox,
    ComboboxInput,
    ComboboxPopover,
    ComboboxList,
    ComboboxOption
} from "@reach/combobox";
import "@reach/combobox/styles.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCrosshairs } from '@fortawesome/free-solid-svg-icons'

const libraries = ["places"];

const center = {
    lat: 27.6981,
    lng: 85.3592
}

const MyLocation = () => {
    const {isLoaded, loadError} = useLoadScript({
        googleMapsApiKey: config.app.googleApi,
        libraries
    });

    const mapRef = useRef();

    const pantTo = useCallback(({lat, lng}) => {
        mapRef.current.panTo({lat, lng});
        mapRef.current.setZoom(14);
        getAddress({lat: lat, lng: lng})
    }, []);

    const getAddress = async ({lat, lng}) => {
        await Geocode.setApiKey(config.app.googleApi)
        await Geocode.fromLatLng(lat, lng).then(
            response => {
                const address = response.results;
                console.log(address);
            },
            error => {
                console.error(error);
            }
        );
    }
    if(loadError) return (<Row>Error</Row>);
    if(!isLoaded) return (<Row>Map</Row>);
    return (
        <>
            <Search panTo={pantTo}/>
        </>
    );
}
const getError = () => {
    return 'Address not Found'
}
const Search = () => {
    const {
        ready,
        value,
        suggestions: {status, data},
        setValue,
        clearSuggestions
    } = usePlacesAutocomplete({
        requestOptions: {
            location: {lat: () => center.lat, lng: () => center.lng},
            radius: 200 * 1000
        }
    })
    const getSearchAddress = async (pos) => {
        await Geocode.setApiKey(config.app.googleApi)
        await Geocode.fromLatLng(pos.coords.latitude, pos.coords.longitude).then(
            response => {
                const address = response.results[0].formatted_address;
                console.log(address);
                setValue(address, false);
            },
            error => {
                console.error(error);
            }
        );
    }
    const options = {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 0
    };
    return (
        <div className={"search-box"}>
            <Combobox onSelect={async (address) => {
                setValue(address, false);
                clearSuggestions();
                try {
                    const results = await getGeocode({address});
                    const {lat, lng} = await getLatLng(results[0]);
                } catch (error) {
                    console.log(error);
                }
            }}>
                <ComboboxInput className="form-control search-box" placeholder={"Restaurant or Food (Leave it blank to browse all)"} value={value} onChange={(e) => setValue(e.target.value)}
                               disabled={!ready} autocomplete={false} />
                <a className={"locate"}
                   onClick={() => {
                       navigator.geolocation.getCurrentPosition(getSearchAddress, getError, options);
                   }}
                >
                    <FontAwesomeIcon icon={faCrosshairs} />
                </a>
                <ComboboxPopover>
                    <ComboboxList>
                        {status === "OK" && data.map(({description, id}) => (
                            <ComboboxOption key={id} value={description} />
                        ))}
                    </ComboboxList>
                </ComboboxPopover>
            </Combobox>
        </div>
    )
}
export default MyLocation;

