import React from "react";

const ProductCard = (props) => {
    return (
        <div className="card product-card">
            <div className="card-img-top" style={{backgroundImage: `url(${props.image})`}} />
            <div className="card-body">
                <h5 className="card-title">{props.title}</h5>
                <p className="card-text">{props.restaurantName}</p>
                <span className="product-rating">
                    <img src="/restaurant/rating.png" alt="rating" />&nbsp;
                    {props.rating}
                </span>
            </div>
        </div>
    )
}
export default ProductCard;