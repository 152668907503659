import React, {useEffect} from "react";
import HomeBanner from "../components/HomeBanner"
import FeaturedRestaurants from "../components/FeaturedRestaurants";
import BestDeals from "../components/BestDeals";
import BestSelectedFood from "../components/BestSelectedFood";
import PopularFood from "../components/PopularFood";
import Partner from "../components/Partner";
import AppDownload from "../components/AppDownload";
import {changeHeaderFooter} from "../helper";
const Restaurant = () => {
    useEffect(() => {
        changeHeaderFooter('restaurant');
    }, []);
    return(
        <>
            <HomeBanner />
            <FeaturedRestaurants />
            <div className="clear-fix" />
            <BestDeals />
            <BestSelectedFood />
            <PopularFood />
            <Partner />
            <AppDownload />
        </>
    )
}

export default Restaurant;