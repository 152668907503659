import React, {useEffect, useState} from "react";
import {setActivePage} from "../../helper";
const TermsCondition = (props) => {

    const [checked, setChecked] = useState(false);
    const [success, setSuccess] = useState('');

    const toggleChecked = () => {
        setChecked(!checked);
    }

    const gotoFinish = (e) => {
        e.preventDefault();
        window.location.href = '/business/congratulations';
    }
    useEffect(() => {
        if(parseInt(localStorage.getItem('activePage')) === 2) {
            setSuccess('Congratulations !!! you have successfully registered your business. Please provide your Identity Here.');
        }
        setTimeout(() => {
            setSuccess('');
        }, 3000);
    }, [parseInt(localStorage.getItem('activePage'))])
    // useEffect(() => {
    //     const socket = socketIOClient("http://localhost:4000");
    //     if(response) {
    //         socket.on("FromAPI", data => {
    //             setResponse(data);
    //             console.log(data);
    //         });
    //     } else {
    //         return () => socket.disconnect();
    //     }
    // }, []);

    return (
        // <div className="col-md-6">
            <div className={"col-md-6 signup-form-section terms"} id={"term-div"}>
                <h5>Terms & Conditions</h5>
                <h6 className={"info-message text-success"}>
                    {success}
                </h6>
                <div className="signup-form row">
                    <div className="desc" dangerouslySetInnerHTML={{
                        __html: props.terms
                    }} />
                    {/*<div className="form-group row">*/}
                    {/*    <p style={{width: '100%'}}>*/}
                    {/*        It's <time dateTime={response}>{response}</time>*/}
                    {/*    </p>*/}
                    {/*</div>*/}
                    <div className="form-check row" style={{width: '100%', marginTop: '10px'}}>
                        <div className="col-md-12">
                            <input defaultChecked={checked} onClick={toggleChecked} className="form-check-input" type="checkbox" id="flexCheckDefault" />
                            <label className="form-check-label" htmlFor="flexCheckDefault">
                                I accept the terms and conditions
                            </label>
                        </div>
                    </div>
                    <div className="form-group row submit-btn">
                        <div className="col-md-6">
                            <button onClick={() => props.setPage(3)} style={{float: 'right', marginTop: '20px', background: '#ccc'}} className="btn text-white">Back</button>
                        </div>
                        <div className="col-md-6">
                            <button disabled={!checked} style={{float: 'left', marginTop: '20px'}} className="btn btn-success" onClick={gotoFinish}>Finish</button>
                        </div>
                    </div>
                </div>
            </div>
        // </div>
    )
}

export default TermsCondition;
