import React from "react";
import {
    Col,
    Row,
    Carousel
} from "react-bootstrap";
import CategoryTitle from "../include/CategoryTitle";
import RestaurantCard from "../include/RestaurantCard";

const FeaturedRestaurants = () => {
    return (
        <Row className={"home-featured-restaurants"}>
            <div className="card-section">
                <CategoryTitle title={"Featured Restaurants"} link={"/"}/>
                <Carousel
                    indicators={false}
                    nextIcon={<span aria-hidden="true" className="carousel-control-next-icon" />}
                    prevIcon={<span aria-hidden="true" className="carousel-control-prev-icon" />}
                    slide={false}
                >
                    <Carousel.Item>
                        <div className="w-100 row">
                            <Col xl={3} lg={3} md={4} sm={6} xs={12}>
                                <RestaurantCard
                                    title={"Restaurant Name"}
                                    image={'/restaurant/1.png'}
                                    status={'open'}
                                    rating={'4.5'}
                                    address={"Restaurant Location, Street, District, Hk"}
                                />
                            </Col>
                            <Col xl={3} lg={3} md={4} sm={6} xs={12}>
                                <RestaurantCard
                                    title={"Restaurant Name"}
                                    image={'/restaurant/1.png'}
                                    status={'close'}
                                    rating={'4.5'}
                                    address={"Restaurant Location, Street, District, Hk"}
                                />
                            </Col>
                            <Col xl={3} lg={3} md={4} sm={6} xs={12}>
                                <RestaurantCard
                                    title={"Restaurant Name"}
                                    image={'/restaurant/1.png'}
                                    status={'open'}
                                    rating={'4.5'}
                                    address={"Restaurant Location, Street, District, Hk"}
                                />
                            </Col>
                            <Col xl={3} lg={3} md={4} sm={6} xs={12}>
                                <RestaurantCard
                                    title={"Restaurant Name"}
                                    image={'/restaurant/1.png'}
                                    status={'open'}
                                    rating={'4.5'}
                                    address={"Restaurant Location, Street, District, Hk"}
                                />
                            </Col>
                            <Col xl={3} lg={3} md={4} sm={6} xs={12}>
                                <RestaurantCard
                                    title={"Restaurant Name"}
                                    image={'/restaurant/1.png'}
                                    status={'open'}
                                    rating={'4.5'}
                                    address={"Restaurant Location, Street, District, Hk"}
                                />
                            </Col>
                            <Col xl={3} lg={3} md={4} sm={6} xs={12}>
                                <RestaurantCard
                                    title={"Restaurant Name"}
                                    image={'/restaurant/1.png'}
                                    status={'open'}
                                    rating={'4.5'}
                                    address={"Restaurant Location, Street, District, Hk"}
                                />
                            </Col>
                            <Col xl={3} lg={3} md={4} sm={6} xs={12}>
                                <RestaurantCard
                                    title={"Restaurant Name"}
                                    image={'/restaurant/1.png'}
                                    status={'open'}
                                    rating={'4.5'}
                                    address={"Restaurant Location, Street, District, Hk"}
                                />
                            </Col>
                            <Col xl={3} lg={3} md={4} sm={6} xs={12}>
                                <RestaurantCard
                                    title={"Restaurant Name"}
                                    image={'/restaurant/1.png'}
                                    status={'close'}
                                    rating={'4.5'}
                                    address={"Restaurant Location, Street, District, Hk"}
                                />
                            </Col>
                        </div>
                    </Carousel.Item>
                    <Carousel.Item>
                        <div className="w-100 row">
                            <Col xl={3} lg={3} md={4} sm={6} xs={12}>
                                <RestaurantCard
                                    title={"Restaurant Name"}
                                    image={'/restaurant/1.png'}
                                    status={'close'}
                                    rating={'4.5'}
                                    address={"Restaurant Location, Street, District, Hk"}
                                />
                            </Col>
                            <Col xl={3} lg={3} md={4} sm={6} xs={12}>
                                <RestaurantCard
                                    title={"Restaurant Name"}
                                    image={'/restaurant/1.png'}
                                    status={'open'}
                                    rating={'4.5'}
                                    address={"Restaurant Location, Street, District, Hk"}
                                />
                            </Col>
                            <Col xl={3} lg={3} md={4} sm={6} xs={12}>
                                <RestaurantCard
                                    title={"Restaurant Name"}
                                    image={'/restaurant/1.png'}
                                    status={'open'}
                                    rating={'4.5'}
                                    address={"Restaurant Location, Street, District, Hk"}
                                />
                            </Col>
                            <Col xl={3} lg={3} md={4} sm={6} xs={12}>
                                <RestaurantCard
                                    title={"Restaurant Name"}
                                    image={'/restaurant/1.png'}
                                    status={'open'}
                                    rating={'4.5'}
                                    address={"Restaurant Location, Street, District, Hk"}
                                />
                            </Col>
                            <Col xl={3} lg={3} md={4} sm={6} xs={12}>
                                <RestaurantCard
                                    title={"Restaurant Name"}
                                    image={'/restaurant/1.png'}
                                    status={'open'}
                                    rating={'4.5'}
                                    address={"Restaurant Location, Street, District, Hk"}
                                />
                            </Col>
                            <Col xl={3} lg={3} md={4} sm={6} xs={12}>
                                <RestaurantCard
                                    title={"Restaurant Name"}
                                    image={'/restaurant/1.png'}
                                    status={'open'}
                                    rating={'4.5'}
                                    address={"Restaurant Location, Street, District, Hk"}
                                />
                            </Col>
                            <Col xl={3} lg={3} md={4} sm={6} xs={12}>
                                <RestaurantCard
                                    title={"Restaurant Name"}
                                    image={'/restaurant/1.png'}
                                    status={'open'}
                                    rating={'4.5'}
                                    address={"Restaurant Location, Street, District, Hk"}
                                />
                            </Col>
                            <Col xl={3} lg={3} md={4} sm={6} xs={12}>
                                <RestaurantCard
                                    title={"Restaurant Name"}
                                    image={'/restaurant/1.png'}
                                    status={'open'}
                                    rating={'4.5'}
                                    address={"Restaurant Location, Street, District, Hk"}
                                />
                            </Col>
                        </div>
                    </Carousel.Item>
                </Carousel>
            </div>
        </Row>
    )
}

export default FeaturedRestaurants;