import React, {useEffect, useState} from 'react';
import {changeHeaderFooter} from "../../helper";
import Background from "../../images/merchant/Bgbig.jpg";
import Congrat from "../../images/merchant/congrat.png";
export default function CongratulationPage() {
    const [height, setHeight] = useState(0);
    const [width, setWidth] = useState(0);

    const getHeight = () => {
        setWidth(window.innerWidth);
        setHeight(window.innerHeight);
    };

    useEffect(()=> {
        changeHeaderFooter();
        localStorage.removeItem('activePage');
        localStorage.removeItem('merchantToken');
        localStorage.removeItem('userId');
        localStorage.removeItem('businessId');
        localStorage.removeItem('businessType');
        localStorage.removeItem('documentId');
        localStorage.removeItem('otp');
    }, []);

    useEffect(() => {
        getHeight();
    }, [height, width]);

    if(localStorage.getItem('email')) {
        return (
            <div className="row congratulation-page">
                <nav style={{height: '40px'}}></nav>
                <div className="page-bg row" style={{backgroundImage: `url(${Background})`, height: height-40, width: width}}>
                    <div className="col-lg-3 d-none d-lg-block d-xl-block"></div>
                    <div className="col-lg-6 col-md-12 text-center">
                        <br/>
                        <h1>Congratulations !</h1><br/>
                        <img src={Congrat} alt="congrat"/><br/>
                        <p className={"desc"}>Your Contract is being prepared. We will notify you soon at</p>
                        <p className="email text-center">{localStorage.getItem('email')}</p>
                        <br/>
                        <a href="/" onClick={() => localStorage.removeItem('email')} className="btn btn-success text-white">Goto Home Page</a>
                    </div>
                    <div className="col-lg-3 d-none d-lg-block d-xl-block"></div>
                </div>
            </div>
        )
    } else {
        return (
            <></>
        )
    }

}