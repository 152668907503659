import React from "react";
import './scss/app.scss';
import Header from './include/Header';
import Footer from "./include/Footer";
import {BrowserRouter} from "react-router-dom";
function App() {
  return (
    <BrowserRouter>
        <div className="App">
            <Header />
            <Footer />
        </div>
    </BrowserRouter>
  );
}

export default App;
