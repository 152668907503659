import React, {useEffect, useState} from "react";
import { Row } from "react-bootstrap";
import {changeHeaderFooter, setActivePage} from "../helper";
import MerchantSignup from "../components/merchant/Signup";
import DocumentUpload from "../components/merchant/DocumentUpload";
import BusinessProfile from "../components/merchant/Business";
import Loader from "../images/loader.gif";
import axios from "axios";
import {config} from "../config";
import TermsCondition from "../components/merchant/Terms";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faChevronLeft} from "@fortawesome/free-solid-svg-icons";
import Background from "../images/merchant/bg.jpg";
import PartnerBg from "../images/merchant/personal.svg";
import BusinessBg from "../images/merchant/business.svg";
import DocumentBg from "../images/merchant/documents.svg";
import TcBg from "../images/merchant/tc.svg";
const Merchant = () => {
    const [businessTypeList] = useState([]);
    const [terms, setTerms] = useState('');
    const [height, setHeight] = useState(0);
    const [width, setWidth] = useState(0);
    const [bgImage, setBgImage] = useState(null);
    const [sectionActive, setActiveSection] = useState(1);

    const getHeight = () => {
        setWidth(window.innerWidth);
        setHeight(window.innerHeight);
    };

    const setPage = (page) => {
        setActiveSection(page);
        setActivePage(page);
        getActiveImage()
    }

    const getActiveImage = () => {
        if(parseInt(localStorage.getItem('activePage')) === 1 || sectionActive === 1) {
            setBgImage(PartnerBg);
            // return PartnerBg;
        } else if(parseInt(localStorage.getItem('activePage')) === 2 || sectionActive === 2) {
            setBgImage(BusinessBg);
        } else if(parseInt(localStorage.getItem('activePage')) === 3 || sectionActive === 3) {
            setBgImage(DocumentBg);
        } else if(parseInt(localStorage.getItem('activePage')) === 4 || sectionActive === 4) {
            setBgImage(TcBg);
        }

    }

    const getBusinessTypeList = async () => {
        return axios.get(`${config.app.api}/businessType`);
    }

    const getTerms = async () => {
        return axios.get(`${config.app.api}/user/terms`);
    }

    useEffect(() => {
        changeHeaderFooter();
        Promise.all([getBusinessTypeList(), getTerms()])
        .then(responses => {
            for(let i=0;i<responses[0].data.length;i++) {
                businessTypeList.push({value: responses[0].data[i]._id, label: responses[0].data[i].name});
            }
            setTerms(responses[1].data[0].terms);
        }).catch(error => {
            console.log(error);
        })
    }, []);

    useEffect(() => {
        getHeight();
    }, [height, width]);

    useEffect(() => {
        if(localStorage.getItem('activePage')) {
            setPage(parseInt(localStorage.getItem('activePage')))
        } else {
            setPage(sectionActive);
        }
    }, [sectionActive]);

    return (
        <Row className={"merchant-page"}  style={{height: height}}>
            <nav>
                <a href="/" className="navbar-brand">
                    <FontAwesomeIcon icon={faChevronLeft} />&nbsp;
                    <span>Return</span>
                </a>
            </nav>
            <div id="background-overlay">
                <img className="spinner-border" src={Loader} alt="Loader"/>
            </div>
            <div className="signup-process-tab">
                <div className="merchant-signup-tab row no-gutters">
                    {/*<p className={'d-block bg-success text-white success-message'}>User Added Successfully</p>*/}
                    <div className="col-md-6 bg-section" style={{backgroundImage: `url(${Background})`, height: height-40}}>
                        <h2>Register Your Business With <span>Storkly</span></h2>
                        <small className={"text-danger"}>(Do not refresh page until the merchant registration process is complete)</small>
                        <img src={bgImage} alt="partner"/>
                    </div>
                    <MerchantSignup
                        businessType={businessTypeList}
                        sectionActive={sectionActive}
                        setPage={setPage}
                    />
                    <BusinessProfile
                        sectionActive={sectionActive}
                        setPage={setPage}
                    />
                    <DocumentUpload
                        sectionActive={sectionActive}
                        setPage={setPage}
                    />
                    <TermsCondition
                        terms={terms}
                        sectionActive={sectionActive}
                        setPage={setPage}
                    />

                </div>{/*<Tabs defaultActiveKey={getActiveKey} id="uncontrolled-tab-example">*/}
                {/*    <Tab eventKey="partner" title="Partner's Profile"*/}
                {/*         disabled={!!localStorage.getItem('merchantToken')} >*/}
                {/*        <MerchantSignup businessType={businessTypeList} />*/}
                {/*    </Tab>*/}
                {/*    <Tab eventKey="business" title="Business Profile"*/}
                {/*         disabled={!!localStorage.getItem('businessId') || !localStorage.getItem('merchantToken')} >*/}
                {/*        <BusinessProfile />*/}
                {/*    </Tab>*/}
                {/*    <Tab eventKey="document" title="Document Upload" tabClassName={"disabled"}>*/}
                {/*        <DocumentUpload />*/}
                {/*    </Tab>*/}
                {/*    <Tab eventKey="terms" title="Terms & Conditions" tabClassName={"disabled"}>*/}
                {/*        */}
                {/*    </Tab>*/}
                {/*</Tabs>*/}
            </div>
        </Row>
    )
}

export default Merchant;