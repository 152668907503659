import React from "react";
import {Carousel, Col, Row} from "react-bootstrap";
import CategoryTitle from "../include/CategoryTitle";
import ProductCard from "../include/ProductCard";

const PopularFood = () => {
    return (
        <Row className={"home-popular-food"}>
            <div className="popular-food">
                <CategoryTitle title={"Popular Food"} link={"/"}/>
                <Carousel
                    indicators={false}
                    nextIcon={<span aria-hidden="true" className="carousel-control-next-icon" />}
                    prevIcon={<span aria-hidden="true" className="carousel-control-prev-icon" />}
                    slide={false}
                >
                    <Carousel.Item>
                        <div className="w-100 row">
                            <Col xl={3} lg={3} md={4} sm={6} xs={12}>
                                <ProductCard
                                    title={"food name"}
                                    restaurantName={"restaurant name"}
                                    image={"/popular/PF2.png"}
                                    rating={'3.2'}
                                />
                            </Col>
                            <Col xl={3} lg={3} md={4} sm={6} xs={12}>
                                <ProductCard
                                    title={"food name"}
                                    restaurantName={"restaurant name"}
                                    image={"/popular/PF1.png"}
                                    rating={'4.2'}
                                />
                            </Col>
                            <Col xl={3} lg={3} md={4} sm={6} xs={12}>
                                <ProductCard
                                    title={"food name"}
                                    restaurantName={"restaurant name"}
                                    image={"/popular/PF2.png"}
                                    rating={'3.2'}
                                />
                            </Col>
                            <Col xl={3} lg={3} md={4} sm={6} xs={12}>
                                <ProductCard
                                    title={"food name"}
                                    restaurantName={"restaurant name"}
                                    image={"/popular/PF1.png"}
                                    rating={'4.2'}
                                />
                            </Col>
                            <Col xl={3} lg={3} md={4} sm={6} xs={12}>
                                <ProductCard
                                    title={"food name"}
                                    restaurantName={"restaurant name"}
                                    image={"/popular/PF2.png"}
                                    rating={'3.2'}
                                />
                            </Col>
                            <Col xl={3} lg={3} md={4} sm={6} xs={12}>
                                <ProductCard
                                    title={"food name"}
                                    restaurantName={"restaurant name"}
                                    image={"/popular/PF1.png"}
                                    rating={'4.2'}
                                />
                            </Col>
                            <Col xl={3} lg={3} md={4} sm={6} xs={12}>
                                <ProductCard
                                    title={"food name"}
                                    restaurantName={"restaurant name"}
                                    image={"/popular/PF2.png"}
                                    rating={'3.2'}
                                />
                            </Col>
                            <Col xl={3} lg={3} md={4} sm={6} xs={12}>
                                <ProductCard
                                    title={"food name"}
                                    restaurantName={"restaurant name"}
                                    image={"/popular/PF1.png"}
                                    rating={'4.2'}
                                />
                            </Col>
                        </div>
                    </Carousel.Item>
                    <Carousel.Item>
                        <div className="w-100 row">
                            <Col xl={3} lg={3} md={4} sm={6} xs={12}>
                                <ProductCard
                                    title={"food name"}
                                    restaurantName={"restaurant name"}
                                    image={"/popular/PF2.png"}
                                    rating={'3.2'}
                                />
                            </Col>
                            <Col xl={3} lg={3} md={4} sm={6} xs={12}>
                                <ProductCard
                                    title={"food name"}
                                    restaurantName={"restaurant name"}
                                    image={"/popular/PF1.png"}
                                    rating={'4.2'}
                                />
                            </Col>
                            <Col xl={3} lg={3} md={4} sm={6} xs={12}>
                                <ProductCard
                                    title={"food name"}
                                    restaurantName={"restaurant name"}
                                    image={"/popular/PF2.png"}
                                    rating={'3.2'}
                                />
                            </Col>
                            <Col xl={3} lg={3} md={4} sm={6} xs={12}>
                                <ProductCard
                                    title={"food name"}
                                    restaurantName={"restaurant name"}
                                    image={"/popular/PF1.png"}
                                    rating={'4.2'}
                                />
                            </Col>
                            <Col xl={3} lg={3} md={4} sm={6} xs={12}>
                                <ProductCard
                                    title={"food name"}
                                    restaurantName={"restaurant name"}
                                    image={"/popular/PF2.png"}
                                    rating={'3.2'}
                                />
                            </Col>
                            <Col xl={3} lg={3} md={4} sm={6} xs={12}>
                                <ProductCard
                                    title={"food name"}
                                    restaurantName={"restaurant name"}
                                    image={"/popular/PF1.png"}
                                    rating={'4.2'}
                                />
                            </Col>
                            <Col xl={3} lg={3} md={4} sm={6} xs={12}>
                                <ProductCard
                                    title={"food name"}
                                    restaurantName={"restaurant name"}
                                    image={"/popular/PF2.png"}
                                    rating={'3.2'}
                                />
                            </Col>
                            <Col xl={3} lg={3} md={4} sm={6} xs={12}>
                                <ProductCard
                                    title={"food name"}
                                    restaurantName={"restaurant name"}
                                    image={"/popular/PF1.png"}
                                    rating={'4.9'}
                                />
                            </Col>
                        </div>
                    </Carousel.Item>
                </Carousel>
            </div>
        </Row>
    )
}
export default PopularFood;