import React from "react";
import {Row} from "react-bootstrap";
import CategoryTitle from "../include/CategoryTitle";
import OwlCarousel from 'react-owl-carousel';
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

const Partner = () => {
    const responsive = {
        responsive:{
            0: {
                items: 2,
            },
            450: {
                items: 2,
            },
            600: {
                items: 3,
            },
            1000: {
                items: 5,
            },
            1200: {
                items: 7
            }
        },
    }
    return (
        <Row className="home-partner">
            <CategoryTitle title={"our partners"} />
            <OwlCarousel className='owl-theme' loop margin={5} dots={false} items={7} nav={false} responsive={responsive.responsive}>
                <div className='item'>
                    <img src="/partners/r1.png" alt="partner" className={"img-fluid"}/>
                </div>
                <div className='item'>
                    <img src="/partners/r2.png" alt="partner" className={"img-fluid"}/>
                </div>
                <div className='item'>
                    <img src="/partners/r3.png" alt="partner" className={"img-fluid"}/>
                </div>
                <div className='item'>
                    <img src="/partners/r4.png" alt="partner" className={"img-fluid"}/>
                </div>
                <div className='item'>
                    <img src="/partners/r5.png" alt="partner" className={"img-fluid"}/>
                </div>
                <div className='item'>
                    <img src="/partners/r6.png" alt="partner" className={"img-fluid"}/>
                </div>
                <div className='item'>
                    <img src="/partners/r7.png" alt="partner" className={"img-fluid"}/>
                </div>
            </OwlCarousel>
        </Row>
    );
}
export default Partner;