import React from "react";
import {Row} from "react-bootstrap";

const AppDownload = () => {
    return (
        <Row className={"app-download"}>
            <img src="/app.png" alt="app" className={"img-fluid"}/>
        </Row>
    )
}
export default AppDownload;