import {
    Row,
    Col
} from 'react-bootstrap';
import MyLocation from "../include/MyLocation";
const HomeBanner = () => {
    return(
        <Row className="home-banner">
            <Col xl={6} lg={8} md={12} className="text-section">
                <div className="clear-fix" />
                <h1>Are You Feeling Hungry?</h1>
                <h4>
                    <span className="dash-symbol">&mdash;</span>
                    <span className="text">Order From your favorite restaurant.</span>
                </h4>
                <div className="clear-fix d-xl-block d-lg-block d-md-block d-none" />
                <form className="search-bar">
                    <MyLocation />
                    {/*<input type="text" className="form-control" placeholder={"Restaurant or Food (Leave it blank to browse all)"}/>*/}
                    <span className="search-btn"><button type="submit">Search</button></span>
                </form>
                <div className="clear-fix d-xl-block d-lg-block d-md-block d-sm-block d-none" />
                <p className="banner-desc d-xl-block d-lg-block d-md-block d-sm-block d-none">
                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
                </p>
            </Col>
            <Col className={"banner-image d-xl-block d-none"} />
        </Row>
    );
}
export default HomeBanner;